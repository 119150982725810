import logo from './logo.gif';
import bae from './bae.mp4'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <video id="baeVideo" autoPlay muted loop>
          <source src={bae} type="video/mp4" />
        </video>
        <article>
          <h1>Good Morning Bae </h1>
        <div>
          <p></p>
          <p>&mdash; Efraim</p>
        </div>
        </article>
      </header>
    </div>
  );
}

export default App;
